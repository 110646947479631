html {
  background-color: var(--background);
  overflow-y: scroll;
}
body::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
}
body::-webkit-scrollbar-track {
  /* background: orange;        color of the tracking area */
}
body::-webkit-scrollbar-thumb {
  background-color: var(--highlight); /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid var(--highlight); /* creates padding around scroll thumb */
}

a {
  color: white;
}

.link {
  color: var(--text);
}
